<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tablePhotocontrol :photocontrols="photo" @editForm="editForm"/>
    </div>
    <modal-photocontrol-edit :id="id"  @refresh="refresh"></modal-photocontrol-edit>
  </div>
</template>

<script>
import tablePhotocontrol from "@/views/component/Table/tablePhotocontrol.vue";
import modalPhotocontrolEdit from "@/views/component/Modal/ModalPhotocontrol/modalPhotocontrolEdit.vue"
export default {
  components: {
    tablePhotocontrol,
    modalPhotocontrolEdit
  },
  data(){
      return{
        performer_id: this.$route.query.filter,
        photo: [],
        showPreloader: false,
        id: null,
      }
  },
  methods:{
    editForm(id) {
      this.id = id
      setTimeout(this.openModal, 0);
    },
    openModal() {
        this.$bvModal.show('photocontrolModalEdit')
    },
    refresh() {
      this.showPreloader = true
      this.$http
      .get(`photo-control/performer-assign/search?filter_performer_id=${this.performer_id}`)
      .then(res =>{
        this.showPreloader = false
        this.photo = res.data
        this.$store.commit('pageData/setdataCount', this.photo.length)
      })
      .catch(err => {
        console.log(err);
      })
    }
  },
  computed: {
      fetchingNewData(){
          return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`photo-control/performer-assign/search?filter_performer_id=${this.performer_id}`)
                .then(res =>{
                    this.photo = res.data
                    this.$store.commit('pageData/setdataCount', this.photo.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    },
  mounted(){
    this.$store.commit('pageData/setdataCount', null)
    this.refresh()
  }
}
</script>
